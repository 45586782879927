import PropTypes from "prop-types";
import "./components.css";
import lock from './Images/lock.png';

//Props for button, size, color, shape, secure case study 
const Button =({
    label, size="lg", 
    onClick, 
    fontWeight="font-regular", 
    color="primary",
    className,
    secure
}) =>{

//Size classes for button

const sizeClasses = {
    sm: "px-4 py-2 h-10 text-sm",
    md: "px-6 py-2 h-12 text-md",
    lg: "px-8 py-2 h-14 text-md"
};

const colorClass = {
    primary: "bg-blue-600 hover:bg-blue-700 text-white",
    secondary: "bg-gray-800 hover:bg-gray-900 text-white",
    //Tertiary buttons - phase 2 (tailwind)
}

const btnClass=`
    ${sizeClasses[size]}
    ${colorClass[color]}
    ${fontWeight}
    rounded-md
    inline-flex items-center justify-center 
    transition-colors
    duration-200
    ease-in-out
    focus:outline-none
    focus:ring-2
    focus:ring-offset-2
    focus:ring-${color}-500
    ${className}
    `;

    return(
        <button className={btnClass} onClick={onClick}>
            {secure && <img src={lock} class="ml-0 mr-2" alt="lock"/> }
            {secure && <span style={{ marginRight: 'mr-4' }}></span>} {label}
        </button>
    );
}


Button.propTypes = {
    label: PropTypes.string,
    size: PropTypes.oneOf(["sm","md","lg"]),
    fontWeight: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    icon: PropTypes.element
}

 export default Button;