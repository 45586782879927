import React from 'react';
import Badge from '../Badge/Badge';

const Gimmick = () => {
    return (
        <>
        <div class="block relative mt-12 mb-12">
            <div class='container'>
                <h1 class="text-4xl md:text-5xl font-bold tracking-tight mb-4 text-gray-900 dark:text-gray-100 leading-tight">
                Gimmick
                </h1>
                <p class="text-lg  md:text-base mb-8 text-gray-600  dark:text-gray-400 ">Exploring Futuristic Design: 3D Objects Using Fusion 360 and Spline</p>
            </div>
        </div>

        <div class="block relative mt-12 mb-12">
                <div class="container">
                    <h4 class="text-xl md:text-2xl font-semibold mb-2 mt-8  text-gray-600 dark:text-gray-200 ">
                        Fusion 360 Samples
                    </h4>
                </div>
                
                {/* Fusion Sample 1 */}
                <div class="container">
                    <h5 class="text-lg md:text-xl font-medium text-gray-600 dark:text-gray-400 leading-snug my-3">
                        Reminder Box
                    </h5>
                    <div className="container-badge">
                                <Badge label="Fusion 360" badgeColor="bg-gradient-4"/>
                                <Badge label="Hardware Prototyping" badgeColor="bg-gradient-4"/>
                                <Badge label="3D Printing / Ultimaker" badgeColor="bg-gradient-4"/>        
                    </div>
                    <p class="text-lg  md:text-base mb-8 mt-4 text-gray-600  dark:text-gray-400">Attachment for trash to remind shared responsibillities for roommates</p>
                </div>    
                <div class="container">
                    <img src={require(`../Images/gimmick/Reminderbox.png`)}  alt="" class="rounded-xl"/>   
                </div>

                {/* Fusion Sample 2 */}
                <div class="container mt-8">
                    <h5 class="text-lg md:text-xl font-medium text-gray-600 dark:text-gray-400 leading-snug my-3">
                        Whack-a-mole for Cognitive Assessment
                    </h5>
                    <div className="container-badge">
                                <Badge label="Fusion 360" badgeColor="bg-gradient-4"/>
                                <Badge label="Hardware Prototyping" badgeColor="bg-gradient-4"/>
                                <Badge label="Raspberry Pi 4" badgeColor="bg-gradient-4"/>        
                                <Badge label="IoT" badgeColor="bg-gradient-4"/>        
                    </div>
                    <p class="text-lg  md:text-base mb-8 mt-4 text-gray-600  dark:text-gray-400">Attachment for trash to remind shared responsibillities for roommates</p>
                </div>    
                <div class="container">
                    <img src={require(`../Images/gimmick/whack-a-mole.png`)}  alt="" className="rounded-xl"/>   
                </div>
        </div>

        <div class="block relative mt-12 mb-12">
                <div class="container">
                    <h4 class="text-xl md:text-2xl font-semibold mb-2 mt-8  text-gray-600 dark:text-gray-200 ">
                        Spline 3D
                    </h4>
                </div>
                
                {/* Spline 3D */}
                <div class="container">
                    <h5 class="text-lg md:text-xl font-medium text-gray-600 dark:text-gray-400 leading-snug my-3">
                        Textures and Model Development in Spline
                    </h5>
                    <div className="container-badge">
                                <Badge label="Textures" badgeColor="bg-gradient-4"/>
                                <Badge label="Animation" badgeColor="bg-gradient-4"/>
                    </div>
                    <p class="text-lg  md:text-base mb-8 mt-4 text-gray-600  dark:text-gray-400">Modeled and textured using Spline 3D, tool based on three.js</p>
                </div>    
                <div class="container">
                    <video height="auto" width="100%" src={require(`../Images/gimmick/current-flow.mp4`)} autoPlay className='rounded-image'/>
                </div>
        </div>
        </>                  
    );
};

export default Gimmick;
