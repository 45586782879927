import React from "react";
import PropTypes from "prop-types";
import "../components.css";

//Receive another prop called badge color

const Badge = ({label, badgeColor}) => {
    
    const badgeClass = `
    rounded-full
    inline-flex
    items-center
    justify-center
    bg-slate-600
    border
    border-slate-500
    font-mono
    text-white
    text-sm
    h-8
    px-3

    ${badgeColor}`;
    return(
        <div>
            <span className={badgeClass}>{label}</span>
        </div>
    );
}

Badge.propTypes = {
    badgeColor: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
}

export default Badge;