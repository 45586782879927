import React from 'react';
import Badge from "../Badge/Badge";
import optumLogo from '../Images/optum.png';
import tcsLogo from '../Images/tcs.png';
import { Link } from 'react-router-dom'; 

const AboutMe = () => {
    return (
       <div>
            <div class="block relative mt-12 mb-12">
                <div class="container grid" >
                    <div class="grid grid-cols-2 gap-4">
                        <div >
                            <img src={require(`../Images/profile-image.jpg`)}  alt="" class="rounded-xl"/>
                        </div> 
                        <div class="flex flex-col items-start relative rounded-xl bg-stone-300/80 dark:bg-gray-800">
                            <div className="mt-8 ml-8 mr-8 px-0 py-1">
                                <div>
                                    <div class="justify-between">
                                        <h3 class="text-2xl md:text-3xl font-extrabold mb-2 text-gray-700 dark:text-gray-200 ">
                                        About me
                                        </h3>
                                        <p class="text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">
                                        I pursued master's degree in Technology Innovation with a specialization in Human-Computer Interaction at the University of Washington's Global Innovation Exchange. I have over eight years of experience designing CRM, internal tools, digital libraries for Tata Consultancy Services.
                                        <br/><br/>
                                        I craft interfaces and interactions and aim to pursue product design for dev tools, APIs, and technical tools. My skill set includes implementing complex user workflows, using advanced user-centric methodologies, and designing next-generation tools.
                                        <br/><br/>
                                        I observe people, culture, and technology through the lens of systems thinking, which gives me a broad contextual awareness and enables me to thrive in ambiguous situations.
                                        <br/><br/>
                                        I am also deeply spiritual and serve as a faculty for SKY campus programs, where I conduct breathwork & meditation programs. I enjoy exploring background scores from movies & games.
                                        <br/><br/>
                                        Let’s connect or drop a mail at abhi.saraf25@gmail.com
                                        </p>
                                    </div>
                                </div>                          
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

            {/*Work experience list and skills*/}
            <div class='container'>
                <h3 class="text-2xl md:text-3xl font-extrabold mb-2 text-gray-700 dark:text-gray-200 ">Skills</h3>
                <div class="flex flex-col items-start relative rounded-xl bg-stone-300/80 dark:bg-gray-800">
                    <div class="m-8">
                        <div class="flex flex-wrap items-start gap-2">{[
                                            ['Interaction Design'],
                                            ['Human-Computer Interaction'],
                                            ['User Research'],
                                            ['Design Systems'],
                                            ['Front-end Development'],
                                            ['Systems Thinking'],
                                            ['Design Thinking'],
                                            ['Prototyping'],
                                            ['Information Architecture'],
                                        ].map(([title, index]) => (
                                <Badge key={index} label={title} badgeColor={"bg-slate-700"}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div class='block relative mt-12 mb-12'>
                <div class="container grid">
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-2 text-gray-700 dark:text-gray-200 ">
                        Work Experience
                    </h3>
                    {/* Optum Work experience */}
                    <div className='grid grid-cols-3'>
                        <div>
                            <div class="m-8">
                                <div class="col-span-1">
                                    <img src={optumLogo} width="auto" height="auto" alt="web-logo" className="image web-logo" />
                                    <p class=" text-gray-600 dark:text-gray-400">Optum | UX Designer | 2022-Present</p>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col col-span-2 items-start relative rounded-xl bg-stone-300/80 dark:bg-gray-800">
                            <div class="m-8">
                                <h4 class="text-xl md:text-2xl font-semibold mb-2  text-gray-600 dark:text-gray-400 ">HouseCalls</h4>
                                <ul class="p-0  list-none">
                                {[
                                            ['Designing data collection systems for clinical teams that record patients’ vitals online and offline on handheld devices during house visits.'],
                                            ['Advancing the design team’s efficiency on by creating reusable components derived from Netra Design System for faster delivery in workflows.'],
                                            ['Fostering the use of clickable prototypes for clarity in designer, developer, and product management collaboration with the clinical team.'],
                                            ['Researching along with clinical teams to discover and design faster methods of data collection, performing usability testing with Advanced Practitioner Care team.'],
                                            ['Currently designing Optum HouseCalls, Client Implementation Engine, and Member Pre-visit Assessment'],
                                            ].map(([title, index]) => (
                                            <li key={index} class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">{title}</li>))}
                                </ul>

                                <h4 class="text-xl md:text-2xl font-semibold mb-2 pt-6 text-gray-600 dark:text-gray-400 ">Client Implementation Engine</h4>
                                <ul class="p-0  list-none">
                                {[
                                            ['Spearheaded the design of information architecture for new clients/partners at Optum.'],
                                            ['Crafted user onboarding flows, design layouts, dashboards, and approval processes that seamlessly integrate the Client Implementation Engine across various applications.'],
                                            ['Developed components contextualizing the Client Implementation Engine to ensure a cohesive user experience.'],
                                            ['Researching along with clinical teams to discover and design faster methods of data collection, performing usability testing with Advanced Practitioner Care team.'],
                                            ['Resolved ambiguous challenges regarding data saving methods at both page and application levels through close collaboration with product management and development teams.'],
                                            ].map(([title, index]) => (
                                            <li key={index} class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">{title}</li>))}
                                </ul>                    
                               
                            </div>
                        </div>
                    </div>

                    {/* TCS Work Experience */}
                    <div className='grid grid-cols-3 mt-8'>
                        <div>
                            <div class="m-8">
                                <div class="col-span-1">
                                    <img src={tcsLogo} width="auto" height="auto" alt="web-logo" className="image web-logo" />
                                    <p class=" text-gray-600 dark:text-gray-400">Tata Consultancy Services | Senior UX Designer | 2015-2021</p>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col col-span-2 items-start relative rounded-xl bg-stone-300/80 dark:bg-gray-800">
                            <div class="m-8">
                                <h4 class="text-xl md:text-2xl font-semibold mb-2 text-gray-600 dark:text-gray-400 ">SmartSales CRM</h4>
                                <ul class="p-0  list-none">
                                {[
                                            ['Designed TCS CRM to cater to special needs for large-value deals, tracking lead progress, easy monitoring of revenue pipelines, and collaboration.'],
                                            ['Conducted E2E research through interviews, cognitive walkthroughs, surveys, and data analysis in partnership with sales enablement team to create research-informed artifacts like information architecture, journey maps, and cross-app API needs.'],
                                            ['Designed E2E assets in form of clickable prototypes, usability test plans, and standardized design systems for the mobile and desktop app future expansion. Onboarded and mentored 2 UX Designers for upcoming project phases.'],
                                            ['Helped onboard +3000 core sales users and their teams enabling them to manage +$15Bn worth of deals on the system.'],
                                            ].map(([title, index]) => (
                                            <li key={index} class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">{title}</li>))}
                                </ul>

                                <h4 class="text-xl md:text-2xl font-semibold mb-2 pt-6 text-gray-600 dark:text-gray-400 ">Pace Port</h4>
                                <ul class="p-0  list-none">
                                {[
                                            ['The research thought leadership lacked visibility of artifacts like patents, whitepapers, and through leaders during customer visits to TCS premises.'],
                                            ['I led the design of the Pace Port digital library that projects research to visitors on multiple form factors like Touch screen TV, web, and mobile, through rapid design and testing, and leveraging existing pattern library.'],
                                            ['Bridged the knowledge gap between researchers and audience, growing visibility to 600,000 employees.'],
                                            ].map(([title, index]) => (
                                            <li key={index} class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">{title}</li>))}
                                </ul>      

                                <h4 class="text-xl md:text-2xl font-semibold mb-2 pt-6 text-gray-600 dark:text-gray-400 ">Mondelez</h4>
                                <ul class="p-0  list-none">
                                {[
                                            ['Mondelez chocolate factory has an established SAP S/4/HANA practice for managing the material flow on ERP and occasionally suffers from data monitoring, we solved the issue through a digital twin model offering by mimicking a digital version of the manufacturing plant unit.'],
                                            ['Improved the efficiency of the plant staff through remote monitoring on the cloud-based digital interface. To gain insights I conducted unstructured research through a cognitive walkthrough and interviews with PM and delivery owners delivering a long-term interface requirement roadmap.'],
                                            ['Delivered a POC by extracting elements of the Vue.js design system and created a clickable prototype and presented it to Mondelez leadership. This led to an opportunity conversion of $500K.'],
                                            ].map(([title, index]) => (
                                            <li key={index} class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">{title}</li>))}
                                </ul>       

                                <h4 class="text-xl md:text-2xl font-semibold mb-2 pt-6 text-gray-600 dark:text-gray-400 ">Software Lifecycle Management</h4>
                                <ul class="p-0  list-none">
                                {[
                                            ['Piled licenses and redundant availability for paid software were an issue, we designed an infrastructure tool where employees could request, transfer, and surrender licenses eventually leading to a 15% reduction in software expenses.'],
                                            ['I partnered with the product owners and conducted semi-structured interviews for the infrastructure teams. Analysing and brainstorming led to features like an agreement repository, and available licenses schedule.'],
                                            ['The emergent features were delivered through wireframes evaluated with structured usability tests. Also did dev hand-off for high-fidelity responsive artifacts.'],
                                            ].map(([title, index]) => (
                                            <li key={index} class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">{title}</li>))}
                                </ul>       
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutMe;
