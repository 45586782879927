import React, { useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';    

const ImageSlideLRM = ({ image1, image2, text }) => {
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
    });

    // Define motion values for transformations
    const x = useTransform(scrollYProgress, [0, 1], ["0%", "20%"]);
    const opacity = useTransform(scrollYProgress, [0.7, 1], [0, 1]);
    const rotateY = useTransform(scrollYProgress, [0, 1], ["10deg", "0deg"]);

    return (
        <div className="relative h-[200vh]" ref={targetRef}>
            <div className="sticky top-0 h-screen flex items-center justify-center">
                <motion.div className="relative grid grid-cols-2 w-full h-full scale-75" style={{ gap: x }}>
                    <motion.img translate='yes' src={image1} alt="AB testing image 1" style={{ rotateY }} />
                    <motion.img translate='yes' src={image2} alt="AB testing image 2" style={{ rotateY }} />
                </motion.div>
                
                <motion.div  style={{opacity}} className="absolute inset-0 flex items-center justify-center">
                    <h3 className="text-2xl md:text-3xl font-bold w-72  text-gray-700 dark:text-gray-200">
                        {text}
                    </h3>
                </motion.div>

                {/* <motion.img translate='yes' src={image3} alt="User prefers infographic for more signups" className="w-auto h-auto" style={{scale,opacity, position:"absolute"}}/> */}
            </div>
        </div>
    );
};

export default ImageSlideLRM;