import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { SiReact } from "react-icons/si";
import { RiTailwindCssFill } from "react-icons/ri";
import { FiFramer } from "react-icons/fi";


const Introtag = () => {
  const [currentState, setCurrentState] = useState(0);
  const [isAnimating, setIsAnimating] = useState(true);

  
const techStack = [
    { icon: SiReact, title: 'React' },
    { icon: FiFramer, title: 'Framer motion' },
    { icon: RiTailwindCssFill, title: 'Tailwind CSS' },
  ];
  const startAnimation = () => {
    // Reset to initial state
    setCurrentState(0);
    setIsAnimating(true);
    
    // Start animation sequence
    const sequence = [
      setTimeout(() => setCurrentState(1), 4000),
      setTimeout(() => {
        setCurrentState(2);
        setIsAnimating(false);
      }, 8000)
    ];

    return sequence;
  };

  useEffect(() => {
    const timers = startAnimation();
    return () => timers.forEach(timer => clearTimeout(timer));
  }, []);

  const handleClick = () => {
    if (!isAnimating) {
      startAnimation();
    }
  };

  return (
    <motion.div 
      onClick={handleClick}
      className="h-10 max-w-fit p-4 flex items-center justify-center border rounded-3xl hover:cursor-pointer bg-slate-800/20 bg-blend-color-burn border-slate-500 dark:border-slate-500/30"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <AnimatePresence mode="wait">
        {currentState === 0 && (
          <motion.div
            key="initial"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            className="flex items-center space-x-2"
          >
            
            <h6 className="font-mono text-sm font-semibold tracking-widest m-0 text-gray-800 dark:text-gray-200">
              DESIGN PORTFOLIO
              <span className="text-sm ml-2 text-gray-600 dark:text-gray-400">by Abhijeet Saraf</span>
            </h6>
            
            
          </motion.div>
        )}

        {currentState === 1 && (
          <motion.div
            key="tech-stack"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex items-center space-x-4"
          >
            <span className="text-sm text-gray-700 dark:text-gray-300">Built using</span>
            <div className="flex space-x-2">
              {techStack.map(({ icon: Icon, title }, index) => (
                <motion.div
                  key={title}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.2 }}
                  className="flex items-center space-x-1 px-2 py-1 border rounded-md border-slate-500 dark:border-slate-700"
                >
                  <Icon size={16} className="text-gray-700 dark:text-gray-300" />
                  <span className="text-xs text-gray-700 dark:text-gray-300">{title}</span>
                </motion.div>
              ))}
            </div>
          </motion.div>
        )}

        {currentState === 2 && (
          <motion.div
            key="final"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            className="flex items-center"
          >
            <h6 className="font-mono text-md font-semibold tracking-widest m-0 text-gray-800 dark:text-gray-200">
              DESIGN × CODE
            </h6>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Introtag;