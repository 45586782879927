import { Sun, Moon } from 'lucide-react';

const Toggle = ({isToggled, onToggle}) => {
    return(
        <div class="flex scale-90" onClick={onToggle}>
            {/* Design for Toggle background */}
            <div className={`w-12 h-7 rounded-full items-center cursor-pointer relative
                ${isToggled ? 'bg-white/50 duration-500' : 'bg-white/80 transition-colors duration-500'}`}>
                {/* Design for Toggle switch with icons */}    
                <div className={`w-5 h-5 m-1 rounded-full transform transition-transform duration-500 flex items-center justify-center
                    ${isToggled ? 'translate-x-0 bg-slate-900 transition-colors duration-500 ease-in-out' : 'translate-x-5 bg-gray-400 transition-colors duration-500 ease-in-out'}`}>
                    {/* Sun icon */}
                    <Sun 
                        className={`absolute w-3 h-3 text-amber-400 transition-opacity duration-500 
                        ${isToggled ? 'opacity-0' : 'opacity-100'}`}
                    />
                    {/* Moon icon */}
                    <Moon 
                        className={`absolute w-3 h-3 text-slate-200 transition-opacity duration-500 
                        ${isToggled ? 'opacity-100' : 'opacity-0'}`}
                    />
                </div>
            </div>
        </div>
    );
}

export default Toggle;