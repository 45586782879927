import React from 'react';
import Slider from 'react-slick';
import "./components.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageSwiper = ({ images }) => {
    const settings = {
        dots: true,
        easing: "ease-in-out",
        centerMode: true,
        infinite: true,
        slidesToShow: 2,
        speed: 500,
        arrows: false,
        // autoplay: true,
        // autoplaySpeed: 2000,
        
    };
    
    return (
        <div>
            <Slider  {...settings}>
            {images.map((image, index) => (
                <div className="slider-image" key={index}>
                    <img className='rounded-image' src={image.src} alt={image.alt} />
                </div>
            ))}
            </Slider>
        </div>
    );
    };

export default ImageSwiper;