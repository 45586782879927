import React from "react";
import "../components.css";

const ProjectHeroPanel = ({
    image,
    label1, 
    label2, 
    label3,
    text1,
    text2,
    text3,
    text4,
    text5,
    text6,
    text7,
    text8,
    text9
    }) => {
    return(
        <div class="block relative mt-12 mb-36">
            <div class="container grid">
            
                <div class="flex flex-col items-start w-full relative rounded-xl bg-stone-300/80 dark:bg-gray-800">
                    <img src={require(`../Images/projectHeroPanel/${image}`)}  alt="" class="rounded-2xl"/>
                </div>

                <div className="grid grid-cols-3 gap-4">
                    <div className="flex flex-col items-start w-full mt-8 p-6 relative rounded-xl bg-stone-300/80 dark:bg-gray-800">
                        <p class="text-lg md:text-base font-semibold mb-2 text-gray-600 dark:text-gray-400 leading-relaxed">{label1}</p>
                        <li class="text-sm md:text-base   text-gray-600 dark:text-gray-400 leading-relaxed">{text1}</li>
                        <li class="text-lg md:text-base   text-gray-600 dark:text-gray-400 leading-relaxed">{text2}</li>
                        <li class="text-lg md:text-base   text-gray-600 dark:text-gray-400 leading-relaxed">{text3}</li>
                        
                    </div>
                    <div className="flex flex-col items-start w-full mt-8 p-6 relative rounded-xl bg-stone-300/80 dark:bg-gray-800">
                        <p class="text-lg md:text-base font-semibold mb-2 text-gray-600 dark:text-gray-400 leading-relaxed">{label2}</p>
                        <li class="text-sm md:text-base   text-gray-600 dark:text-gray-400 leading-relaxed">{text4}</li>
                        <li class="text-lg md:text-base   text-gray-600 dark:text-gray-400 leading-relaxed">{text5}</li>
                        <li class="text-lg md:text-base   text-gray-600 dark:text-gray-400 leading-relaxed">{text6}</li>
                        
                        </div>
                    <div className="flex flex-col items-start w-full mt-8 p-6 relative rounded-xl bg-stone-300/80 dark:bg-gray-800">
                        <p class="text-lg md:text-base font-semibold mb-2 text-gray-600 dark:text-gray-400 leading-relaxed">{label3}</p>
                        <li class="text-sm md:text-base   text-gray-600 dark:text-gray-400 leading-relaxed">{text7}</li>
                        <li class="text-lg md:text-base   text-gray-600 dark:text-gray-400 leading-relaxed">{text8}</li>
                        <li class="text-lg md:text-base   text-gray-600 dark:text-gray-400 leading-relaxed">{text9}</li>
                        
                    </div>    
                </div>           
            </div>
        </div>
        
    );
};

export default ProjectHeroPanel;
