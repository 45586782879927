import React, { useState } from 'react';
import leftArrow from './Images/arrow-left.png';
import rightArrow from './Images/arrow-right.png';


const ImageSlider = ({ images,scaleImageFactor}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextSlide = () => {
    setCurrentImageIndex((prevIndex) => {
      const nextIndex = prevIndex === images.length - 1 ? 0 : prevIndex + 1;
      return nextIndex;
    });
  };

  const prevSlide = () => {
    setCurrentImageIndex((prevIndex) => {
      const nextIndex = prevIndex === 0 ? images.length - 1 : prevIndex - 1;
      return nextIndex;
    });
  };
  
  const getImageClassName = () => {
    // Calculate the scaled width based on the scaleImageFactor
    return `rounded-image ${scaleImageFactor}`;
  };

  return (
    <div className="panel-body">
      <img
        src={images[currentImageIndex].src}
        alt={images[currentImageIndex].alt}
        key={currentImageIndex}
        className={getImageClassName()} 
      />
      
      {/* Slider Button */}
      <div className='layout-grid grid-halves' > 
        <button className="rounded-button" onClick={prevSlide} >
            <img className="scale50" src={leftArrow} alt="previous"/>
        </button>
        <button className="rounded-button" onClick={nextSlide}>
            <img className="scale50" src={rightArrow} alt="previous"/>
        </button>
      </div>
      
    </div>
  );
};

export default ImageSlider;