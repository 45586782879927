import React from "react";
import ProjectHeroPanel from "../ProjectHeroPanel/ProjectHeroPanel";
import ScrollToTopButton from "../ScrollToTopButton";
import ImageSwiper from "../ImageSwiper";
import "../components.css";


const designSystemOverview = [
    { src: require(`../Images/opportunity/web-text-components.png`), alt: 'Image 1' },
    { src: require(`../Images/opportunity/web-color-components.png`), alt: 'Image 2' },
    { src: require(`../Images/opportunity/web-components.png`), alt: 'Image 3' },
];

const offeringWireframes = [
    { src: require(`../Images/opportunity/offering-wireframes-1.jpg`), alt: 'Image 1' },
    { src: require(`../Images/opportunity/offering-wireframes-2.jpg`), alt: 'Image 2' },
    { src: require(`../Images/opportunity/offering-wireframes-3.jpg`), alt: 'Image 3' },
    { src: require(`../Images/opportunity/offering-wireframes-4.jpg`), alt: 'Image 4' },
    { src: require(`../Images/opportunity/offering-wireframes-5.jpg`), alt: 'Image 5' },
    { src: require(`../Images/opportunity/offering-wireframes-6.jpg`), alt: 'Image 6' },
    { src: require(`../Images/opportunity/offering-wireframes-7.jpg`), alt: 'Image 7' },
    { src: require(`../Images/opportunity/offering-wireframes-8.jpg`), alt: 'Image 8' },
    { src: require(`../Images/opportunity/offering-wireframes-9.jpg`), alt: 'Image 9' },
    { src: require(`../Images/opportunity/offering-wireframes-10.jpg`), alt: 'Image 10' },
    { src: require(`../Images/opportunity/offering-wireframes-11.jpg`), alt: 'Image 11' },
];

const sketches = [
    { src: require(`../Images/opportunity/sketches-1.png`), alt: 'Image 1' },
    { src: require(`../Images/opportunity/sketches-2.png`), alt: 'Image 1' },
    { src: require(`../Images/opportunity/sketches-3.png`), alt: 'Image 1' },
    { src: require(`../Images/opportunity/sketches-4.png`), alt: 'Image 1' },
    { src: require(`../Images/opportunity/sketches-5.png`), alt: 'Image 1' },
];

    
function Smartsales() {

    return(
        <div>              
            <ScrollToTopButton/>
            <ProjectHeroPanel
                image="opportunity-tile-banner.png"
                label1="Key contributions"
                label2="Team"
                label3="Duaration"
                text1="Research with key stakeholders Sales enablement teams, product managers."
                text2="Wireframing and usability testing of hypotheses and delivery of mock-ups."
                text3="Establishing and implementing design system and mentoring 2 junior designers."
                text4="2 Product Manager, 2 Business Analysts"
                text5="30-35 Developer and QA resources"
                text6="Senior UX Designer, 2 Visual Designer"
                text7="Phase 1 2018-2019"
                text8="Phase 2 2020-2021"
                text9="Phase 3 2020-2021">
            </ProjectHeroPanel> 

            {/* Overview Section of the project */}
            <div class="block relative mt-12 mb-12">
                <div class="container">
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500 ">
                        OVERVIEW
                    </h6>
                    <h1 class="text-4xl md:text-5xl font-bold tracking-tight mb-4 text-gray-900 dark:text-gray-100 leading-tight">
                        TCS SmartSales
                    </h1>
                    <p class="text-lg  md:text-base mb-8 text-gray-600  dark:text-gray-400 ">In 2018, TCS Internal decided to retire Oracle's Siebel CRM and build their own CRM tool. As part of the team, I participated right from database disintegration and led the design of two core modules: Opportunity & Insights. Additionally, I guided a parallel team in designing a mobile platform for quick updates.</p>
                    <h4 class="text-xl md:text-2xl font-semibold mb-2 mt-8  text-gray-600 dark:text-gray-200 ">
                        Impact
                    </h4>
                    <div class="flex flex-col p-4 items-start w-full relative rounded-xl bg-stone-300/80 dark:bg-gray-800">
                        <div className="panel-body ">
                            <div class="grid grid-cols-3 gap-x-8 w-full ">
                                <h2 class="text-3xl md:text-4xl font-semibold text-gray-800 dark:text-gray-200 leading-snug my-1">+3000</h2>
                                <h2 class="text-3xl md:text-4xl font-semibold text-gray-800 dark:text-gray-200 leading-snug my-1">70%</h2>
                                <h2 class="text-3xl md:text-4xl font-semibold text-gray-800 dark:text-gray-200 leading-snug my-1">+$15 Bn</h2>
                                <p class="text-lg md:text-base text-gray-600 dark:text-gray-400 leading-relaxed ">Sales users onboarded on new CRM platform</p>
                                <p class="text-lg md:text-base text-gray-600 dark:text-gray-400 leading-relaxed ">Reduction in CRM expense at TCS</p>
                                <p class="text-lg md:text-base text-gray-600 dark:text-gray-400 leading-relaxed ">Worth of deals managed on SmartSales CRM</p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Interface Screens for Users */}
            <div class="black relative mt-24 mb-12">
                <div class="container">
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2  tracking-widest text-indigo-500">
                            SMARTSALES INTERFACE
                    </h6>
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200 ">Dashboard - Sales performace</h3>
                    <p class="text-lg  md:text-base mb-4 text-gray-600  dark:text-gray-400 ">Landing page: Personas addressed: Business Relationship Managers, Business Development Managers, and teams</p>
                    
                </div>    
                <div class="container">
                    <img src={require(`../Images/opportunity/web-dashboard-opportunity.jpg`)}  alt="" class="rounded-xl"/>   
                </div>    
                
                <div class="container">
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-4 mt-8 text-gray-700 dark:text-gray-200 ">Opportunity Details</h3>
                    <p class="text-lg  md:text-base mb-4 text-gray-600  dark:text-gray-400 ">Details view of an opportunity - Overview of the deal timeline. Users monitor deals’ progress and add details under respective sections.  Collaborative space for Sales team to work on the leads.</p>
                </div>
                <div class="container">
                    <img src={require(`../Images/opportunity/web-opportunity-deatils-page-1.jpg`)}  alt="" class="rounded-xl"/> 
                </div>    
                <div class="container">
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-4 mt-8 text-gray-700 dark:text-gray-200 ">Mobile Application</h3>
                    <p class="text-lg  md:text-base mb-4 text-gray-600  dark:text-gray-400 ">Opportunity mobile app is a unique blend of curated features for users while they are on the move, the app allows easy monitoring, quick notes, create teams, and helps in negotiation.</p>
                </div>
                
                <div class="container">
                {/* Mobile Screens  */}
                    <div class="grid grid-cols-3 gap-4">
                        <div class="flex flex-col gap-4">
                            <img src={require(`../Images/opportunity/mobile-dashboard.png`)}  alt="" class="rounded-xl"/>   
                                <div class="flex flex-col items-start relative rounded-xl p-8 h-max bg-stone-300/80 dark:bg-gray-800">
                                    <p class="text-lg  md:text-base text-gray-600  dark:text-gray-400">Dashboard provides easy monitoring of the opportunity performance.</p>
                                </div>
                        </div>
                        <div class="flex flex-col gap-4">
                            <img src={require(`../Images/opportunity/mobile-opportunities-details.png`)}  alt="y" class="rounded-xl"/>   
                                <div class="flex flex-col items-start relative rounded-xl p-8 h-max bg-stone-300/80 dark:bg-gray-800">
                                    <p class="text-lg  md:text-base text-gray-600  dark:text-gray-400">Opportunity detailsallows easy manipulation of various status and stages.</p>
                                </div>
                        </div>
                        <div class="flex flex-col gap-4">
                            <img src={require(`../Images/opportunity/mobile-offerings.png`)}  alt="" class="rounded-xl"/>   
                                <div class="flex flex-col items-start relative rounded-xl p-8 h-max bg-stone-300/80 dark:bg-gray-800">
                                    <p class="text-lg  md:text-base text-gray-600  dark:text-gray-400">Offering details helps you negotiate easily with clients and change in data.</p>
                                </div>
                        </div>
                    </div>
                </div>               
            </div>

            {/* Business Goals and Proposition */}
            <div class="block relative mt-24 mb-12">
                <div class="container">
                <h6 class="font-mono text-lg md:text-base font-semibold mb-2 tracking-widest text-indigo-500  ">
                    BUSINESS GOALS AND PROPOSITION
                    </h6>
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-2 text-gray-700 dark:text-gray-200 ">
                    Leverage data, build internal capability & cost cuts on software expenditure
                    </h3>
                    <h4 class="text-xl md:text-2xl font-semibold mb-2  text-gray-600 dark:text-gray-400 ">
                    Challenges in current tool
                    </h4>
                    <div class="flex flex-col rounded-xl p-4 bg-stone-300/80 dark:bg-gray-800">
                        <ul class="p-0 list-none">
                            <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Lack of user-centered design and aesthetics.</li>
                            <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Overlapping functionalities</li>
                            <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Obsolete features and bulky tool</li>
                        </ul>
                    </div>
                    <h4 class="text-xl md:text-2xl font-semibold mb-2 mt-8  text-gray-600 dark:text-gray-400 ">
                    Interface snapshot of Oracle Siebel
                    </h4>
                    <img src={require(`../Images/opportunity/web-siebel-interface.png`)}  alt="" class="rounded-xl align-middle"/>   
                    <div class="flex flex-col rounded-xl p-8 mt-8 bg-stone-300/80 dark:bg-gray-800">
                        <h4 class="text-xl md:text-2xl font-semibold mb-2 text-gray-600 dark:text-gray-400 ">
                        Design Question
                        </h4>
                        <h5 class="text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed">How can we create a toolset to foster accountability towards TCS customer needs, enhance collaboration in teams and provide curated features to improve productivity?</h5>
                        
                    </div>
                </div>
            </div>
            
            {/* User Research */}
            <div class="block relative mt-24 mb-12">
                <div class="container">
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500  ">
                        RESEARCH OUTLINE
                    </h6>
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200">Divided research into following phases</h3>
                    <img src={require(`../Images/opportunity/web-research-timeline.png`)}  alt="" className="rounded-xl"/>                      
                    <h4 class="text-xl md:text-2xl font-semibold mb-2 mt-8  text-gray-600 dark:text-gray-400 ">
                        Journey Map
                    </h4>
                    <p class="text-lg md:text-base  text-gray-600  dark:text-gray-400">Business development managers, Business relationship managers, Pre Sales teams, and Business and technology unit.</p>
                    <p class="text-lg mb-8 md:text-base  text-gray-600  dark:text-gray-400">The core understanding from the map was an understanding of users’ frequency to interact with opportunities and core tasks in specific phases.</p>
                    <img src={require(`../Images/opportunity/web-journey-map.png`)}  alt="" className="rounded-xl"/>   

                    {/* Pain Points and Emerging Design Requirements */}
                    <div class="flex flex-col rounded-xl p-8 mt-8 bg-stone-300/80 dark:bg-gray-800">
                        <div >
                            <h4 class="text-xl md:text-2xl font-semibold text-gray-600 dark:text-gray-400 ">
                                Pain points
                            </h4>
                            <ul class="list-none p-0">
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Hard to determine the exact positioning of the opportunity and actions taken on opportunity progress.</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Delays in initiating parallel workflows for workforce management(HR) and contract management(Legal).</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Extraneous processes and a bulky interface and lacks aesthetic appeal.</li>
                            </ul>
                        </div>
                        <div class=" mt-8">
                        <div>
                            <h4 class="text-xl md:text-2xl font-semibold  text-gray-600 dark:text-gray-400 ">Emerging design requirements</h4>
                            <ul class="list-none p-0">
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">An easy method to track opportunity progress that fits sales needs.</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Clear display of opportunity progress and upcoming major events.</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Option to initiate parallel workflows for sales teams.</li>
                            </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

            {/* Solutioning */}
            <div class="block relative mt-24 mb-12">
                <div class="container">
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500  ">
                        BRAINSTORMING AND SKETCHES
                    </h6>
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200">Sketches reflecting pain-points</h3>
                    <h4 class="text-xl md:text-2xl font-semibold mb-4 text-gray-600 dark:text-gray-400 ">Features for determining opportunity progress, products, and workflows for HR and Legal.</h4>
                </div>
                {/* <div className="container space-bottom-extra-large">
                    <img src={require(`../Images/opportunity/web-wireframe-group-2.png`)}  alt="" className="rounded-image"/>   
                </div>   */}
                <ImageSwiper images={sketches} />     
                <div class="container">
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-4 mt-8 text-gray-700 dark:text-gray-200">Low-fidelity wireframes</h3>
                </div>    
                {/* <div className="container space-bottom-extra-large">
                    <img src={require(`../Images/opportunity/web-low-fi-wireframes-1.png`)}  alt="" className="rounded-image space-bottom-large"/>
                    <img src={require(`../Images/opportunity/web-low-fi-wireframes-2.png`)}  alt="" className="rounded-image space-bottom-extra-large"/>
                </div>    */}
                <ImageSwiper images={offeringWireframes} />          
                <div class="container">
                    <div class="flex flex-col rounded-xl p-8 mt-8 bg-stone-300/80 dark:bg-gray-800">
                        <h3 class="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200">Comparison of task-flows from legacy app to SmartSales - Opportunity</h3>
                        <h4 class="text-xl md:text-2xl font-semibold mb-4 text-gray-600 dark:text-gray-400 ">Key differentiators</h4>
                        <ul class="list-none p-0">
                            <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Redesigned task flows considering the user inputs from various personas in the opportunity lifecycle.</li>
                            <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Wherein old task flow was ideal if the users were trained on the foundations of using the system, SmartSales was calibrated with minimal features and didn't require explicit training.</li>
                            <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">The task flow was optimized to cater to future requirements for scalability.</li>
                        </ul> 
                    </div>
                </div>        
                <div class="container">
                    <img src={require(`../Images/opportunity/web-task-flows-old-new.png`)}  alt="" class="rounded-xl mt-8"/>           
                </div>    
            </div>

            {/* Design System */}
            <div class="block relative mt-24 mb-12">
                <div class="container ">
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500  ">
                        DESIGN SYSTEM AND PATTERN LIBRARY
                    </h6>
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200">Glimpse of reusable pattern library with tokens</h3>
                </div>    
                {/* <div className="container space-bottom-extra-large space-top-medium">
                    <img src={require(`../Images/opportunity/web-text-components.png`)}  alt="" className="rounded-image space-bottom-large"/>
                    <img src={require(`../Images/opportunity/web-color-components.png`)}  alt="" className="rounded-image space-bottom-large"/>
                    <img src={require(`../Images/opportunity/web-components.png`)}  alt="" className="rounded-image space-bottom-large"/>
                </div>  */}
                <ImageSwiper images={designSystemOverview} />
            </div>

            {/* Further details */}
            <div class="block relative mt-24 mb-12">
                <div class="container">                    
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500  ">
                        FURTHER DETAILS
                    </h6>
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200">Numerous stories to share for subsequent phases delivering automation, checkout flow and extending design system.</h3>
                    
                    <p class=" text-gray-600 dark:text-gray-400">Let's connect or drop me a mail at abhi.saraf25@gmail.com</p>
                </div>
            </div>

        </div>
    );
};
      

export default Smartsales;