import React from "react";
import "./components.css";
import arrowUp from './Images/arrow-up.png';


const ScrollToTopButton = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    
    return (
        <button className="rounded-button scroll-button"  onClick={ScrollToTopButton}>
            <img className="scale50" src={arrowUp} alt="previous"/>
        </button>
    );

};
    
    

export default ScrollToTopButton;
