import React, { useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';

const ImageStack = ({ image }) => {
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
    });

    // Define motion values for transformations
    const x = useTransform(scrollYProgress, [0, 1], ["300%", "0%"]);
    const y = useTransform(scrollYProgress, [0, 1], ["50%", "80%"]);
    const z = useTransform(scrollYProgress, [0, 1], ["0%", "50%"]);
    const scale = useTransform(scrollYProgress, [0, 1], [2, 3]);
    const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);
    const skewX = useTransform(scrollYProgress, [0, 1], ["0", "30deg"]);
    const skewY = useTransform(scrollYProgress, [0, 1], ["5deg", "10deg"]);
    const skewZ = useTransform(scrollYProgress, [0, 1], ["-10deg", "30deg"]);
    const rotateX = useTransform(scrollYProgress, [0, 1], ["-5deg", "10deg"]);
    const rotateY = useTransform(scrollYProgress, [0, 1], ["0deg", "30deg"]);
    const rotateZ = useTransform(scrollYProgress, [0, 1], ["-10deg", "5deg"]);
    
    return (
        <div className="relative h-[200vh]" ref={targetRef}>
            <div className="sticky top-0 h-screen flex items-center justify-center overflow-hidden">
                <motion.div
                    // style={{ x, opacity }}
                    className="relative flex"
                >
                    {image.map((img, index) => {
                        // Calculate offsets for diagonal stacking
                        
                        return (
                            <motion.div
                                key={index}
                                className="relative"
                                style={{zIndex: image.length - index, // Ensure proper stacking
                                }}
                            >
                                <motion.img
                                    translate='yes'
                                    src={img.src}
                                    alt={`Image ${index}`}
                                    className="w-auto h-auto"
                                    style={{
                                        x,
                                        rotateY,
                                        scale,
                                        skewY,
                                        borderRadius:"8px",
                                        perspective: "500px",
                                        transformStyle: "preserve-3d",
                                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                                    }}
                                />
                            </motion.div>
                        );
                    })}
                </motion.div>
            </div>
        </div>
    );
};

export default ImageStack;
