import React from 'react';
import { useState,useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import {motion} from 'framer-motion';
import Toggle from '../toggle';
import logoImage from '../Images/60x60.png';
import emailIcon from '../Images/email.png';
import githubIcon from '../Images/github.png';
import linkedinIcon from '../Images/linkedin.png';


const Nav = ({isDarkMode, setIsDarkMode}) => {
  
const handleToggle = () =>{
    setIsDarkMode(!isDarkMode);
  };
  
  return (
    <motion.div 
    initial={{ y:-20, opacity: 0 }}
    animate={{ y:0, opacity: 1 }}
    transition={{  ease:"easeOut", duration:0.5 }} class='container '> 
      <nav class=" mt-5 py-3 relative rounded-lg z-50 bg-stone-300 shadow-2xl shadow-slate-400 border border-stone-300 dark:bg-slate-950/70 dark:shadow-2xl dark:shadow-slate-950 dark:border dark:border-slate-900">
        <div class='flex-1'>
          <div className='flex justify-between items-center'>
            
            <a href="/" aria-current="page" className="" aria-label="home">
              <img src={logoImage} width="auto" height="auto" alt="web-logo" class="relative scale-75 ml-4" />
            </a>
            <a href="/" aria-current="page" aria-label="home">
            <h5 class="text-lg md:text-xl font-bold text-gray-600 dark:text-gray-200 leading-snug my-3">
            Abhijeet Saraf
            </h5>
            </a>
            <div class="flex justify-between items-center ml-auto mr-8">
              {[
               /*["/dev","Dev Mode"],*/  
               ["/", "Home"],
               ["/gimmick", "Gimmick"],
               ["https://drive.google.com/file/d/1FeztXO-I19iHjuzyyO4OlnLcCAggGxTw/view?usp=drive_link", "Resume"],
               ["/aboutme", "About me"],
               ].map(([route,title]) => (
                <Link class="text-gray-500 hover:text-gray-900 dark:text-white/50 transition-colors duration-300 ease-in-out dark:hover:text-white mr-8" to={route}>{title}</Link>
               ))}
              {[
                ["mailto:abhi.saraf25@gmail.com",emailIcon],
                ["https://github.com/abhijeetsaraf",githubIcon],
                ["https://www.linkedin.com/in/abhijeet-saraf/",linkedinIcon],
              ].map(([link,icon])=>(
                <a target='blank' aria-current="page" href={link}>
                  <img class="opacity-50 mr-6 transition-opacity duration-300 ease-in-out hover:opacity-100 [filter:brightness(0)_saturate(0)_opacity(0.6)] dark:brightness-0 dark:invert" width="auto" height="auto" alt="web-logo" src={icon}/>
                </a>
              ))}
              <Toggle isToggled={isDarkMode} onToggle={handleToggle}/>
            </div>    
          </div>
        </div>      
      </nav>
    </motion.div>
  );
};

export default Nav;