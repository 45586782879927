import ProjectHeroPanel from "../ProjectHeroPanel/ProjectHeroPanel";
import { motion, useScroll, useTransform } from 'framer-motion';
import ScrollToTopButton from "../ScrollToTopButton";
import ImageSwiper from "../ImageSwiper";
import "../components.css";
import { Link } from "react-router-dom";
import React, { useRef } from 'react';
import ImageStack from "../Imagestack";
import ImageSlideLRM from "../ImageSlideLRM";

//AB testing image imports
const abtest1 =  require(`../Images/previsit/checkin-abtest-1.png`);
const abtest2 =  require(`../Images/previsit/checkin-abtest-2.png`);

const interactionValidation = [
    { src: require(`../Images/previsit/validating-interation-pattern.png`), alt: 'Image 1' },
    { src: require(`../Images/previsit/validating-taparea.png`), alt: 'Image 2' },
    { src: require(`../Images/previsit/validating-touch-targets.png`), alt: 'Image 3' },
];

const componentGroup = [
    { src: require(`../Images/optum/text-inputs-1.png`), alt: 'Image 1' },
    { src: require(`../Images/optum/multi-inputs-1.png`), alt: 'Image 2' },
    { src: require(`../Images/optum/multi-inputs-2.png`), alt: 'Image 3' },
    { src: require(`../Images/optum/toggle.png`), alt: 'Image 3' },
    { src: require(`../Images/optum/other-1.png`), alt: 'Image 3' },
    { src: require(`../Images/optum/other-2.png`), alt: 'Image 3' },
    { src: require(`../Images/optum/other-3.png`), alt: 'Image 3' },
];


const stackImages = [
    {src: require(`../Images/previsit/test1.png`)},
    {src: require(`../Images/previsit/test2.png`)},
    {src: require(`../Images/previsit/test3.png`)},
    {src: require(`../Images/previsit/test4.png`)},
    {src: require(`../Images/previsit/test5.png`)},
    {src: require(`../Images/previsit/test6.png`)},
    {src: require(`../Images/previsit/test7.png`)},
    {src: require(`../Images/previsit/test8.png`)},
];


function Previsit(){
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
            target: targetRef,
        });
    
        // Define motion values for transformations
        const opacity = useTransform(scrollYProgress, [0.5, 1], [1, 0]);
    return(
        <>
            
           
            {/* <ScrollToTopButton/> */}
            <ProjectHeroPanel
                image="previsit-banner.png"
                label1="Key contributions"
                label2="Team"
                label3="Duaration"
                text1="Design system assessment for seniors ( +65 age )"
                text2="Advanced prototyping with Figma variables and logic"
                text3="Usability testing and refined heuristics"
                text4="Clinical team"
                text5="Optum Research Group"
                text6="Engineering Lead, and Sr UX Designer"
                text7="2 Weeks"
                text8="2 Quarter Support"
                text9=""
            >
            </ProjectHeroPanel>

            
            {/* ----------------------------------------------Overview Section of the project----------------------------------------- */}
            <div class="block relative mt-8 mb-12">
                <div class="container">
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500  ">
                         OVERVIEW
                    </h6>
                    <h1 class="text-4xl md:text-5xl font-bold tracking-tight mb-4 text-gray-900 dark:text-gray-100 leading-tight">
                    Optum - Pre-Visit Assessment
                    </h1>
                    <p class="text-lg  md:text-base mb-8 text-gray-600  dark:text-gray-400 ">
                        A HouseCalls visit includes a comprehensive inventory of the member’s health history. Completing
                        this part of the visit takes approximately 10 minutes. Currently, some members have the option to
                        complete this health assessment before the visit via phone. This allows the APC (Advanced
                        Practice Clinician) to spend more time focusing on the member and their specific questions or
                        needs.
                    </p>
                    <a href="https://www.optum.com/en/business/health-plans/members/in-home-clinical-assessment.html" class=" text-indigo-600 dark:text-indigo-400 hover:underline font-medium">
                    Read about HouseCalls
                    </a>
                    <h4 class="text-xl md:text-2xl font-semibold mb-2 mt-8  text-gray-600 dark:text-gray-200 ">
                        Streamlining Healthcare Onboarding: From Phone to Digital Assessments
                    </h4>
                    <p class="text-lg  md:text-base mb-8 text-gray-600  dark:text-gray-400 ">
                        However, with 30k+ pre-visit assessments completed each month, telephonic outreach is
                        also resource intensive. Therefore, a digital version of the assessment is being introduced.
                    </p>            
                   
                    <h4 class="text-xl md:text-2xl font-semibold mb-2 mt-8  text-gray-600 dark:text-gray-200 ">
                        Achievements
                    </h4>
                   <div className="grid grid-cols-4 gap-4">
                        <div className="flex flex-col items-start w-full mt-4 p-6 relative rounded-xl border border-indigo-800 bg-stone-300/80 dark:bg-gray-800">
                            <p class="text-lg md:text-base font-semibold mb-2 text-gray-600 dark:text-gray-200 leading-relaxed">Usability Optimization for Seniors</p>
                        </div>
                        <div className="flex flex-col items-start w-full mt-4 p-6 relative rounded-xl border border-indigo-800 bg-stone-300/80 dark:bg-gray-800">
                            <p class="text-lg md:text-base font-semibold mb-2 text-gray-600 dark:text-gray-200 leading-relaxed">Comprehensive Assessment Design</p>
                        </div>
                        <div className="flex flex-col items-start w-full mt-4 p-6 relative rounded-xl border border-indigo-800 bg-stone-300/80 dark:bg-gray-800">
                            <p class="text-lg md:text-base font-semibold mb-2 text-gray-600 dark:text-gray-200 leading-relaxed">Rapid Prototyping using variables</p>
                        </div>
                        <div className="flex flex-col items-start w-full mt-4 p-6 relative rounded-xl border border-indigo-800 bg-stone-300/80 dark:bg-gray-800">
                            <p class="text-lg md:text-base font-semibold mb-2 text-gray-600 dark:text-gray-200 leading-relaxed">Interface Learnability via Usability testing</p>
                        </div>  
                        
                    </div>   
                    
                    <hr class="mt-8 mb-8 border-slate-800" />
                </div>
            </div>
            {/*------------------------Test image interaction----------------------------  */}
            
            <div className="relative block mt-12 mb-12">
                <div class="container">
                        <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500 ">
                            PRE-VISIT ASSESSMENT INTERFACE DESIGN
                        </h6>
                        <h3 class="text-2xl md:text-3xl font-extrabold -mb-10 text-gray-700 dark:text-gray-200 ">
                            Web designs of Pre-Visist Assessment
                        </h3>
                </div>                
                <ImageStack image={stackImages}/>
                  
                <ImageSlideLRM image1={abtest1} image2={abtest2} text="A/B Testing yielded high sign-up metrics, with factors like inforgraphic and info messages"/>
                <div className="container relative flex flex-row" ref={targetRef}>
                    
                        <img src={require(`../Images/previsit/prototype-flows.png`)} className="scale-110 z-0" alt=""/>
                        <motion.h3 style={{opacity}} className="absolute top-0 right-0 text-2xl md:text-3xl font-extrabold mb-8 w-80 z-10 text-gray-700 dark:text-gray-200 mt-8">
                            Rapid Prototyping using variables and logic flows to render dynamic content.
                        </motion.h3>
                    
                </div>
            </div>
           
      
            {/* --------------------------------------------------------- */}

            <div class="block relative mt-24 mb-12">
                <div class="container mt-12"> 
                    {/* Unordered List  */}
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500">
                        NAVIGATING USER RESEARCH
                    </h6>
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200">Design exercise and Heuristics development</h3>
                    <div className="flex flex-col relative rounded-xl mt-4 bg-stone-300/80 dark:bg-gray-800">
                        <div class="m-8">
                            <ul class="p-0 list-none">
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-none-4 my-4">Categorizing challenges that affect the success of digital pre-visit assessment by card sorting and mood boards.</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-none-4 my-4">Culminating set of heuristics to evaluate form usability.</li>
                            </ul>
                            
                        </div>
                    </div>
                    <h4 class="text-xl md:text-2xl font-semibold mt-4 mb-2  text-gray-600 dark:text-gray-200">
                            Outcomes
                    </h4>
                    <div className="flex flex-col relative rounded-xl mt-4 bg-stone-300/80 dark:bg-gray-800">
                        <div class="m-8">
                            <ul class="p-0 list-none">
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-none-4 my-4">Apart from standard heuristics we took additional consideration for the following.  </li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-none-4 my-4">Testing and implementation of of WCAG AAA standards, verifying design system elements for touch targets and interaction patterns, account for reduced motor control with forgiving tap areas, minimizing memory load.</li>
                            </ul>
                            
                    </div>
                        
                </div>
                    
            </div>

                {/* <div className="mt-8">
                    <ImageSwiper images={interactionValidation}/>
                </div> */}
            

        

                            
            <div class="block relative mt-24 mb-12">
                <div class="container">
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 mt-12 tracking-widest text-indigo-500">
                        USABILITY EVALUATION WITH SENIORS
                    </h6>
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-2 text-gray-700 dark:text-gray-200 ">
                         Usability testing with seniors
                    </h3>
                    <p class="text-lg md:text-base  text-gray-600  dark:text-gray-400 ">Uncovered critical insights into interface design that enhances learning and interaction for elderly users.</p>
                    <img src={require(`../Images/previsit/participants.png`)}  alt=""/>
                    <div className="flex flex-col items-start relative rounded-xl mt-4 bg-stone-300/80 dark:bg-gray-800">
                        <div class="m-8">
                        <h4 class="text-xl md:text-2xl font-semibold mb-2  text-gray-600 dark:text-gray-400 ">
                        Outcomes from moderated user testing
                        </h4>
                            <ul class="p-0 list-none">
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading--none-4 my-4">Enhanced Communication: Clearly communicate the estimated completion time (15 minutes), share benefits of completing the form in advance.</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading--none-4 my-4">Improved Accessibility: Design updates should account for varied user interactions, such as zoom, touchscreen, and interruptions. Include a "save and return" feature to accommodate returning users.</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading--none-4 my-4">Additional Fields for Home Visits: Introduce optional fields for specific access instructions (e.g., “use side door”) and presence of pets to better prepare for home visit scenarios.</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading--none-4 my-4">Post-Submission Enhancements: Revise the final screen to include instructions for modifying or canceling appointments, and clarify form access post submission.</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Outlier scenarios that the design system can't accomodate, thus tweaking component for senior friendly interface.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                </div>
            </div>

            {/* Conclusion  */}
             {/* Final Deliverable */}
             <div class="block relative mt-24 mb-12">
                <div class="container">
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500">
                            FURTHER DETAILS
                    </h6>
                    <br></br>
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200">
                    Let’s connect to explore the detailed findings behind the design of a senior-friendly digital platform. This solution effectively addresses cognitive, physical, and environmental challenges, streamlining onboarding while enhancing accessibility and user experience.</h3>
                    <p class="text-lg mb-8 md:text-base  text-gray-600  dark:text-gray-400 ">Let's connect, drop me at mail at abhi.saraf25@gmail.com</p>
                </div>
            </div>
        </>
    )
};

export default Previsit;